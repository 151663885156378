<template></template>

<script>
import httpService from "../js/httpservice";
export default {
  methods: {
    getdigiLockerData(code, state) {
      let json = {
        code: code,
        state: state,
      };
      httpService.getDigiLocker(json).then((response) => {
        if (response.status == 200) {
          this.$router.push("/createcustomer");
        }
      });
    },
  },
  mounted: function () {
    var parameters = this.$route.query;
    // console.log(parameters);
    var code = this.$route.query.code;
    // console.log(code);
    var state = this.$route.query.state;
    // console.log(state);
    this.getdigiLockerData(code, state);
  },
};
</script>

<style>
/* https://pc-ekyc.stoneagesolutions.com/digilockerVerification?code=82ee1eff1752e149dc8e4a708161a5b484654209&state=4 */
</style>